<form [formGroup]="fg" class="login-form">
  <div class="title-l mb-12">
    {{ title | translate }}
  </div>

  <div class="body-m mb-24">
    {{ subTitle | translate }}
  </div>

  <div class="flex flex-col mb-24">
    <label for="password" class="mb-4">{{
      'LOGIN.CURRENT_PASSWORD' | translate
    }}</label>
    <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordPostfix1">
      <input
        nz-input
        id="currentPassword"
        [type]="showPasswordAsText1 ? 'text' : 'password'"
        formControlName="current_pass"
        [placeholder]="'LOGIN.CURRENT_PASSWORD' | translate"
        [nzStatus]="fg.controls['current_pass'] | formError"
      />
    </nz-input-group>
    <app-ie-form-error
      [field]="fg.controls['current_pass']"
    ></app-ie-form-error>
    @if (fg.hasError(formErrors.INVALID_CREDENTIALS)) {
      <div class="flex label-m items-center cl-error">
        {{ 'FORGOT_PASSWORD.INVALID_CREDENTIALS' | translate }}
      </div>
    }
  </div>

  <div class="flex flex-col mb-24">
    <label for="password" class="mb-4">{{
      'LOGIN.NEW_PASSWORD' | translate
    }}</label>
    <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordPostfix2">
      <input
        nz-input
        id="password"
        [type]="showPasswordAsText2 ? 'text' : 'password'"
        formControlName="new_pass"
        [placeholder]="'LOGIN.NEW_PASSWORD' | translate"
        [nzStatus]="fg.controls['new_pass'] | formError"
      />
    </nz-input-group>
    <app-ie-form-error [field]="fg.controls['new_pass']"></app-ie-form-error>
    @if (fg.hasError(formErrors.PASSWORDS_SHOULD_BE_DIFFERENT)) {
      <div class="flex label-m items-center cl-error">
        {{ 'FORGOT_PASSWORD.PASSWORDS_SHOULD_BE_DIFFERENT' | translate }}
      </div>
    }
  </div>

  <div class="flex flex-col mb-24">
    <label for="password" class="mb-4">{{
      'LOGIN.CONFIRM_PASSWORD' | translate
    }}</label>
    <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordPostfix3">
      <input
        nz-input
        id="password22"
        [type]="showPasswordAsText3 ? 'text' : 'password'"
        formControlName="confirm_pass"
        [placeholder]="'LOGIN.CONFIRM_PASSWORD' | translate"
        [nzStatus]="fg.controls['confirm_pass'] | formError"
      />
    </nz-input-group>
    <app-ie-form-error
      [field]="fg.controls['confirm_pass']"
    ></app-ie-form-error>
  </div>

  <ng-content></ng-content>

  @if (showPasswordStrength) {
    <div class="label-s mb-8">
      {{ 'LOGIN.YOUR_PASSWORD_MUST_HAVE' | translate }}:
    </div>

    <app-password-strength
      [password]="fg.value['new_pass']!"
      [hideLine]="true"
    ></app-password-strength>
  }
</form>
<ng-template #passwordPrefix>
  <ie-icons icon="security-user" [width]="20" [height]="20"></ie-icons>
</ng-template>

<ng-template #passwordPostfix1>
  @if (!showPasswordAsText1) {
    <ie-icons
      class="show-hide-password"
      icon="hide"
      [width]="20"
      [height]="20"
      (click)="showPasswordAsText1 = !showPasswordAsText1"
    ></ie-icons>
  } @else {
    <ie-icons
      class="show-hide-password"
      icon="show"
      [width]="20"
      [height]="20"
      (click)="showPasswordAsText1 = !showPasswordAsText1"
    ></ie-icons>
  }
</ng-template>
<ng-template #passwordPostfix2>
  @if (fg.controls['new_pass'].value && fg.controls['new_pass'].valid) {
    <ie-icons
      class="show-hide-password done-icon"
      icon="done-v"
      [width]="20"
      [height]="20"
    ></ie-icons>
    <div class="vertical-line"></div>
  }
  @if (!showPasswordAsText2) {
    <ie-icons
      class="show-hide-password"
      icon="hide"
      [width]="20"
      [height]="20"
    ></ie-icons>
  } @else {
    <ie-icons
      class="show-hide-password"
      icon="show"
      [width]="20"
      [height]="20"
      (click)="showPasswordAsText2 = !showPasswordAsText2"
    ></ie-icons>
  }
</ng-template>
<ng-template #passwordPostfix3>
  @if (
    fg.controls['new_pass'].value === fg.controls['confirm_pass'].value &&
    fg.controls['new_pass'].valid
  ) {
    <ie-icons
      class="show-hide-password done-icon"
      icon="done-v"
      [width]="20"
      [height]="20"
    ></ie-icons>
    <div class="vertical-line"></div>
  } @else if (fg.controls['confirm_pass'].value) {
    <ie-icons
      class="show-hide-password close-icon"
      icon="close"
      [width]="20"
      [height]="20"
    ></ie-icons>
    <div class="vertical-line"></div>
  }
  @if (!showPasswordAsText3) {
    <ie-icons
      class="show-hide-password"
      icon="hide"
      [width]="20"
      [height]="20"
      (click)="showPasswordAsText3 = !showPasswordAsText3"
    ></ie-icons>
  } @else {
    <ie-icons
      class="show-hide-password"
      icon="show"
      [width]="20"
      [height]="20"
      (click)="showPasswordAsText3 = !showPasswordAsText3"
    ></ie-icons>
  }
</ng-template>
